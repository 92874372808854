import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import SubpageHero from "../components/subpage-hero";
import NewsletterSection from "../components/newsletter-section";
import TextImage from "../components/text-image";
import Seo from "../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title="Polityka Prywatności Fundacji Enjoy The Ride" mdxType="Seo" />
    <SubpageHero mdxType="SubpageHero">
      <h1>{`Polityka prywatności`}</h1>
      <p>{`FUNDACJA ENJOY THE RIDE`}</p>
    </SubpageHero>
    <div className="etr-container">
      <div className="max-w-content-lg mx-auto">
        <h2>{`INFORMACJA NA TEMAT PRZETWARZANIA DANYCH OSOBOWYCH`}</h2>
        <p>{`Zgodnie z przepisami ogólnego rozporządzenia o ochronie danych (2016/679) potocznie zwanego RODO informujemy, iż administratorem Twoich danych osobowych jest Fundacja Enjoy The Ride z siedzibą w Białymstoku (15-215) przy ul. Konopnickiej 14/8, ujawniona w Rejestrze Stowarzyszeń, Innych Organizacji Społecznych i Zawodowych, Fundacji oraz Samodzielnych Publicznych Zakładów Opieki Zdrowotnej oraz Rejestrze Przedsiębiorców KRS prowadzonym przez Sąd Rejonowy w Białymstoku (XII Wydział Gospodarczy Krajowego Rejestru Sądowego) pod numerem 0000875617, posługująca się numerem REGON 387784214 i NIP 5423422076.`}</p>
        <br />
        <h2>{`Z KIM MOGĘ KONTAKTOWAĆ SIĘ W SPRAWACH DOTYCZĄCYCH OCHRONY DANYCH?`}</h2>
        <p>{`We wszystkich sprawach dotyczących ochrony danych osobowych możesz kontaktować się z osobą zajmującą się sprawami ochrony danych w Fundacji pod adresem mailowym `}<a parentName="p" {...{
            "href": "mailto:kontakt@enjoytheride.pl"
          }}>{`kontakt@enjoytheride.pl`}</a>{`.`}</p>
        <br />
        <h2>{`W JAKIM CELU SĄ PRZETWARZANE MOJE DANE?`}</h2>
        <p>{`Administrator przetwarzana Twoje dane osobowe w następujących celach, obok których podano podstawy prawne przetwarzania:`}</p>
        <ul>
          <li parentName="ul">{`prowadzenia działalności analityczno-statystycznej użytkowników odwiedzających stronę enjoytheride.pl – art. 6 ust. 1 lit. f RODO;`}</li>
          <li parentName="ul">{`zapewniania pełnej funkcjonalności strony enjoytheride.pl, jej responsywności i interoperacyjności – art. 6 ust. 1 lit. f RODO;`}</li>
          <li parentName="ul">{`zapewniania możliwości nawiązania kontaktu z Fundacją Enjoy The Ride – art. 6 ust. 1 lit. f RODO;`}</li>
          <li parentName="ul">{`prawnie uzasadnione interesy administratora, na które składają się obsługa, dochodzenie i obrona przed wzajemnymi roszczeniami, zapobieganie czynom zabronionym, realizacja marketingu usług własnych, prowadzenie wewnętrznych badań statystycznych dotyczących działania enjoytheride.pl, badanie poziomu zadowolenia z jakości działania enjoytheride.pl oraz rozpatrywanie reklamacji związanych z nieprawidłowym działaniem strony – na podstawie art. 6 ust. 1 lit. f RODO.`}</li>
        </ul>
        <br />
        <h2>{`KTO BĘDZIE ODBIORCĄ MOICH DANYCH?`}</h2>
        <p>{`Twoje dane osobowe będą ujawniane podmiotom współpracującym z administratorem. Takie podmioty nazwa się odbiorcami danych. Odbiorcami danych osobowych będą podmioty uprawnione do uzyskania danych osobowych na podstawie odrębnych przepisów prawa, dostawcy usług hostingu, analitycznych, statystycznych oraz reklamodawcy. Niektórzy z nich, to podmioty, którym administrator powierzył przetwarzanie danych osobowych na podstawie umowy. Wszystkie przypadki ujawniania Twoich danych osobowych odbywają się w sposób w pełni zgodny z prawem, w tym z przepisami RODO. Administrator przed ujawnieniem Twoich danych podmiotowi trzeciemu zawsze weryfikuje, czy takie działanie jest uzasadnione i niezbędne, a także, czy czy Twoje dane są odpowiednio chronione.`}</p>
        <br />
        <h2>{`CZY MOJE DANE TRAFIAJĄ POZA EUROPEJSKI OBSZAR GOSPODARCZY?`}</h2>
        <p>{`Co do zasady administrator przetwarza Twoje dane osobowe wyłącznie na obszarze Unii Europejskiej. Miej jednak na uwadze, że w związku z korzystaniem przez administratora z narzędzi oraz rozwiązań technicznych dostarczanych przez osoby trzecie, Twoje dane osobowe (w tym dane statystyczne i analityczne) zgromadzone w związku z korzystaniem przez Ciebie ze strony enjoytheride.pl mogą być przekazywane tzw. państwa trzeciego w rozumieniu RODO, tj. do USA. USA to państwo, które nie należy do Europejskiego Obszaru Gospodarczego i nie spełnia tak wysokich wymogów ochrony danych osobowych jak państwa do niego należące. Aby temu przeciwdziałać zawarliśmy umowy powierzenia przetwarzania, które zapewniają odpowiedni poziom bezpieczeństwa Twoich danych. W przypadku danych przekazywanych poza Europejski Obszar Gospodarczy, umowa jest oparta o standardowe klauzule umowne zatwierdzone przez Komisję Europejską. W ramach oceny skutków dla ochrony danych, zgodnie z zaleceniami po wyroku c-311/18, określiliśmy ryzyko i ewentualne skutki związane z przekazywaniem danych. Taki sposób przetwarzania jest w pełni legalny i zgodny z RODO. Miej na uwadze, że w przypadku korzystania przez Ciebie z funkcji pozwalających na udostępnienia treści zawartych na stronie enjoytheride.pl za pomocą serwisów społecznościowych takich jak np. Facebook, Twoje dane będą mogły trafiać poza Europejski Obszar Gospodarczy z Twojej własnej inicjatywy. Z uwagi na powyższe zalecamy zapoznanie się z politykami prywatności poszczególnych serwisów internetowych przez skorzystaniem z takich funkcji.`}</p>
        <br />
        <h2>{`JAK DŁUGO BĘDĄ PRZETWARZANE MOJE DANE?`}</h2>
        <p>{`Przetwarzamy Twoje dane osobowe, a raczej dane statystyczne i analityczne powstałe w oparciu o Twoją aktywność na enjoytheride.pl przez 3 lata od chwili, w której odwiedziłeś enjoytheride.pl`}</p>
        <br />
        <h2>{`JAKIE PRAWA PRZYSŁUGUJĄ MI W ZWIĄZKU Z PRZETWARZANIEM MOICH DANYCH OSOBOWYCH?`}</h2>
        <p>{`Przysługuje Tobie prawo do żądania od administratora sprostowania, usunięcia lub ograniczenia przetwarzania Twoich danych osobowych. Dodatkowo masz prawo wniesienia sprzeciwu co do przetwarzania Twoich danych osobowych. Prawa te przysługują Tobie tylko w sytuacjach, w których wniesienie takiego żądania jest faktycznie uzasadnione.`}</p>
        <br />
        <h2>{`CZY MUSZĘ PODAWAĆ MOJE DANE OSOBOWE?`}</h2>
        <p>{`Jeśli tylko przeglądasz enjoytheride.pl to nie musisz podawać swoich danych osobowych. Jeśli nie chcesz aby Twoje dane osobowe były poddawane działaniom analitycznym i statystycznym możesz skorzystać ze specjalnych wtyczek do przeglądarki, które uniemożliwiają zbieranie danych (np. poprzez podawanie fikcyjnych informacji o Twojej przeglądarce czy Twoim sposobie zachowania).`}</p>
        <br />
        <h2>{`ZAUTOMATYZOWANE PRZETWARZANIE DANYCH OSOBOWYCH`}</h2>
        <p>{`Twoje dane osobowe będą przetwarzane w sposób zautomatyzowany, ale nie będą podlegały profilowaniu.`}</p>
        <br />
        <h2>{`PRAWO DO SKARGI`}</h2>
        <p>{`Przysługuje Tobie prawo do wniesienia skargi w związku z tym, jak przetwarzane są Twoje dane osobowe do organu nadzorczego, którym jest Prezes Urzędu Ochrony Danych Osobowych z siedzibą w Warszawie (00-193), przy ul. Stawki 2.`}</p>
        <br /> <br /> 
      </div>
    </div>
    <NewsletterSection mdxType="NewsletterSection" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      